
import {Component, Vue} from 'vue-property-decorator';

@Component({
  head: {
    title() {
      return {
        inner: 'Csc',
        complement: 'Error'
      };
    },
    link() {
      return [
        {rel: 'stylesheet', h: 'assets/specific.css', id: 'specific'},
        {rel: 'shortcut icon', h: 'assets/favicon.ico', id: 'favicon'}

      ];
    }
  },
})


export default class Error extends Vue {
  public translationsReady = false;

  public created() {
    this.$languages.changeLanguage(<string>this.$route.query.lang, <string>this.$route.query.tenant_id).then((lang: string) => {
      this.$vuetify.lang.current = lang;
      console.log('Using language:', lang);
      this.translationsReady = true;
    });

  }
}
